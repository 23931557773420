<template>
  <div>
    <!-- <div class="scenario-header-overlay"></div> -->
    <div class="scenario-header">
      <q-item>
        <q-item-section side top v-if="!isReport" class="q-pr-sm">
          <q-btn unelevated rounded color="primary" icon="add" label="コンテンツを追加" id="card-node">
            <q-menu v-model="assetMenuShowing">
              <q-list dense style="min-width: 100px">
                <q-item class="bg-grey-9 text-white">
                  <q-item-section>{{ $t('label.push.type') }}</q-item-section>
                </q-item>
                <q-separator />

                <!-- Type Question -->
                <q-item clickable @click="openSelectNextQuestion">
                  <q-item-section>{{ $t('menu.q_a') }}</q-item-section>
                </q-item>

                <!-- Type Message -->
                <q-item clickable @click="openSelectNextMessage">
                  <q-item-section>{{ $t('menu.message') }}</q-item-section>
                </q-item>

                <!-- Type Goal -->
                <q-item clickable @click="openSelectNextGoal">
                  <q-item-section>{{ $t('menu.goal') }}</q-item-section>
                </q-item>
                <!-- Type Form -->
                <q-item clickable @click="openSelectNextForm">
                  <q-item-section>{{ $t('menu.form') }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-item-section>

        <q-item-section side top v-if="!isReport && isUndo" class="q-pl-sm q-pr-sm">
          <q-btn size="sm" round outline color="primary" icon="undo" id="undo-node" @click="onUndo"> </q-btn>
        </q-item-section>
        <q-item-section side top v-if="!isReport && currentIndex < sessionScenarios.length - 1" class="q-pl-sm">
          <q-btn size="sm" round outline color="primary" icon="redo" id="redo-node" @click="onRedo"> </q-btn>
        </q-item-section>

        <q-item-section side top class="q-pr-sm" :class="!isReport ? 'q-pl-sm' : ''">
          <q-btn size="sm" round outline color="primary" icon="zoom_in" @click="onZoomIn" :disable="zoomLevel >= 200">
          </q-btn>
        </q-item-section>
        <q-item-section side top class="q-pr-none">
          <label class="text-grey-9 q-pt-xs"> {{ displayZoom + ' %' }}</label>
        </q-item-section>
        <q-item-section side top class="q-pl-sm q-pr-sm cursor-pointer">
          <q-btn size="sm" round outline color="primary" icon="zoom_out" @click="onZoomOut" :disable="zoomLevel <= 0">
          </q-btn>
        </q-item-section>
        <q-item-section side top class="q-pr-sm q-pl-sm scenario-header__date" v-if="!isReport">
          <q-radio size="sm" round outline v-model="currentDirection" val="0" :label="$t('label.horizontal')" />
        </q-item-section>
        <q-item-section side top class="q-pl-sm q-pr-sm scenario-header__date cursor-pointer" v-if="!isReport">
          <q-radio size="sm" round outline v-model="currentDirection" val="1" :label="$t('label.vertical')" />
        </q-item-section>
        <q-item-section avatar class="cursor-move scenario-header__date q-pl-sm" v-if="isReport">
          <DateRangeSelectorV2
            v-model="value"
            :disable="false"
            @update:onChangeDateRange="changeDateRange"
          ></DateRangeSelectorV2>
        </q-item-section>
        <q-item-section class="cursor-pointer" v-if="!isReport">
          <a @click="onOpenHistory()" class="scenario-header__last-edit">{{ titleLastHistory }}</a>
        </q-item-section>
        <q-space />
        <q-item-section class="cursor-pointer q-pr-none" side top>
          <q-btn unelevated color="primary" label="シナリオテンプレート" @click="onOpenCopyScenarios"></q-btn>
        </q-item-section>
        <q-item-section class="cursor-pointer q-pr-none" side top>
          <q-btn
            size="sm"
            round
            outline
            @click="onTransmissionLog"
            text-color="primary"
            :label="$t('label.story.log')"
          />
        </q-item-section>
        <q-item-section class="cursor-pointer q-pr-none" side top>
          <q-btn size="sm" round outline color="primary" icon="play_circle" id="undo-node" @click="onOpenPreview">
          </q-btn>
        </q-item-section>
        <q-item-section class="cursor-pointer q-pr-none top-expand" side top>
          <q-btn size="sm" round outline color="primary" icon="arrow_drop_up" id="top-expand" @click="onExpand('top')">
          </q-btn>
        </q-item-section>
        <q-item-section class="cursor-pointer q-pr-none left-expand" top>
          <q-btn size="sm" round outline color="primary" icon="arrow_left" id="left-expand" @click="onExpand('left')">
          </q-btn>
        </q-item-section>
      </q-item>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { ICard } from 'bot-flow-maker/src/types'
import { EScenarioResourceType, IDateRangePicker } from '@/utils/types'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'

@Options({
  components: { DateRangeSelectorV2 },
  directives: { maska },
  emits: [
    'update:modelValue',
    'update:onReUndoCards',
    'update:openSelectNextQuestion',
    'update:openSelectNextMessage',
    'update:openSelectNextGoal',
    'update:openSelectNextForm',
    'update:handleZoomInOut',
    'update:onChangeDirection',
    'update:onTransmissionLog',
    'update:onOpenPreview',
    'update:onOpenHistory',
    'update:initSessionScenarios',
    'update:openCopyScenarios',
  ],
})
export default class ScenarioHeader extends Vue {
  @Prop()
  modelValue!: IDateRangePicker

  @Prop()
  currentScenario!: ICard[]

  @Prop()
  isReport!: boolean

  @Prop()
  titleLastHistory!: string

  sessionScenarios: ICard[][] = []
  currentIndex = 0
  isChanged = true
  currentDirection = '0'
  zoomLevel = 100
  ZOOM_SPEED = 10
  assetMenuShowing = false

  get displayZoom() {
    return Math.round(this.zoomLevel)
  }

  get value() {
    return this.modelValue
  }

  set value(value: IDateRangePicker) {
    this.$emit('update:modelValue', value)
  }

  get isUndo() {
    return this.currentIndex > 0
  }

  get isRedo() {
    return this.currentIndex > 0 && this.currentIndex < this.sessionScenarios.length - 1
  }

  get appId() {
    return this.$route?.params?.appId
  }

  get campaignId() {
    return this.$route?.params?.campaignId
  }

  get isTestMode() {
    return this.$route?.params?.isTestMode === 'true'
  }

  changeDateRange(value) {
    this.value = value
  }

  @Watch('currentScenario')
  onChangeValue() {
    if (!this.isChanged || this.currentScenario.length <= 0) {
      this.isChanged = true
      return
    }
    this.sessionScenarios.push(cloneDeep(this.currentScenario))
    this.currentIndex = this.sessionScenarios.length - 1
  }

  @Watch('currentDirection')
  onChangeDirection() {
    this.$emit('update:onChangeDirection', this.currentDirection)
  }

  onUndo() {
    this.currentIndex = this.currentIndex - 1
    this.isChanged = false
    this.$emit('update:onReUndoCards', this.sessionScenarios[this.currentIndex])
  }

  onRedo() {
    this.currentIndex = this.currentIndex + 1
    this.isChanged = false
    this.$emit('update:onReUndoCards', this.sessionScenarios[this.currentIndex])
  }

  onZoomIn() {
    if (this.zoomLevel > 200) {
      this.zoomLevel = 200
    }
    this.zoomLevel = this.zoomLevel + this.ZOOM_SPEED
    this.$emit('update:handleZoomInOut', this.zoomLevel, 'in')
  }

  onZoomOut() {
    this.zoomLevel = this.zoomLevel - this.ZOOM_SPEED
    if (this.zoomLevel < 0) {
      this.zoomLevel = 0
    }
    this.$emit('update:handleZoomInOut', this.zoomLevel, 'out')
  }

  onExpand(side) {
    this.currentScenario.forEach((element) => {
      const card = document.getElementById(element.uniqueId) as HTMLElement
      const style = window.getComputedStyle(card)
      // eslint-disable-next-line no-undef
      const matrix = new WebKitCSSMatrix(style.transform)
      let transformX = matrix.m41
      let transformY = matrix.m42
      if (side === 'top') {
        transformY += 30
        element.top += 30
      } else {
        transformX += 30
        element.left += 30
      }
      card.style.transform = 'translate(' + transformX + 'px, ' + transformY + 'px)'
    })
    this.updateCardLine(side)
    this.onChangeValue()
  }

  updateCardLine(side) {
    const lines = document.getElementsByClassName('leader-line')
    for (let i = 0; i < lines.length; i++) {
      const line = document.getElementById(lines[i].id) as HTMLElement
      let top = parseFloat(line.style.top.replace('px', ''))
      let left = parseFloat(line.style.left.replace('px', ''))
      if (side === 'top') {
        top += 30
        line.style.top = top + 'px'
      } else {
        left += 30
        line.style.left = left + 'px'
      }
    }
  }

  openSelectNextQuestion() {
    this.$emit('update:openSelectNextQuestion', {
      cardType: EScenarioResourceType.question,
    })
    this.assetMenuShowing = false
  }

  openSelectNextMessage() {
    this.$emit('update:openSelectNextMessage', {
      cardType: EScenarioResourceType.message,
    })
    this.assetMenuShowing = false
  }

  openSelectNextGoal() {
    this.$emit('update:openSelectNextGoal', {
      cardType: EScenarioResourceType.goal,
    })
    this.assetMenuShowing = false
  }

  openSelectNextForm() {
    this.$emit('update:openSelectNextForm', {
      cardType: EScenarioResourceType.form,
    })
    this.assetMenuShowing = false
  }

  onOpenHistory() {
    this.$emit('update:onOpenHistory')
  }

  onTransmissionLog() {
    this.$emit('update:onTransmissionLog')
  }

  onOpenPreview() {
    this.$emit('update:onOpenPreview')
  }

  onOpenCopyScenarios() {
    this.$emit('update:openCopyScenarios')
  }

  setDirectionType(value) {
    this.currentDirection = value
  }

  setTileHistory(value) {
    this.directionType = value
    this.$refs.scenarioHeader.setDirectionType(value)
  }

  resetData() {
    this.sessionScenarios = []
    this.currentIndex = 0
    this.isChanged = true
    this.currentDirection = '0'
    this.zoomLevel = 100
  }

  handleUndo() {
    if (!this.isReport && this.isUndo) {
      this.onUndo()
    }
  }
}
</script>

<style lang="scss" scoped>
.scenario-header-overlay {
  height: 48px;
  width: 100%;
}

.scenario-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  &__last-edit {
    text-decoration: underline;
  }

  &__date {
    :deep(.q-radio__inner) {
      font-size: 32px !important;
    }
    :deep(.q-field__control),
    :deep(.q-field__append) {
      height: 32px;
    }
  }
  &__border {
    border-right: double;
  }
}

.scenario-header .q-item__section {
  justify-content: center;
}
</style>

<template>
  <CLoading :loading="loading" />
  <ScenarioHeaderSelector
    ref="scenarioHeader"
    @update:openSelectNextQuestion="openSelectNextQuestion"
    @update:openSelectNextGoal="openSelectNextGoal"
    @update:openSelectNextMessage="openSelectNextMessage"
    @update:openSelectNextForm="openSelectNextForm"
    @update:onReUndoCards="onReUndoCards"
    @update:handleZoomInOut="handleZoomInOut"
    @update:onOpenPreview="onOpenPreview"
    @update:onChangeDirection="onChangeDirection"
    @update:onOpenHistory="onOpenHistory"
    @update:initSessionScenarios="initScenarios"
    :isReport="isReport"
    v-model="dateRange"
    :currentScenario="localValue"
    :titleLastHistory="titleLastHistory"
  ></ScenarioHeaderSelector>
  <panZoom
    ref="panZoom"
    selector=".zoomable"
    id="panzoomContainer"
    :options="{
      autocenter: true,
      bounds: false,
      minZoom: 0,
      maxZoom: 4,
      startX: 0,
      startY: 48,
      disablePan: true,
      disableZoom: true,
      smoothScroll: true,
      zoomDoubleClickSpeed: 1,
      beforeWheel: function (e) {
        // allow wheel-zoom only if altKey is down. Otherwise - ignore
        var shouldIgnore = !e.altKey
        return false
      },
      beforeMouseDown: function (e) {
        // allow mouse-down panning only if altKey is down. Otherwise - ignore
        var shouldIgnore = !e.altKey
        return false
      },
    }"
    @transform="onTransform"
    @mousewheel="onPauseZoom"
    @mousedown="onPauseZoom"
  >
    <div class="scenario zoomable">
      <section class="scenario__container" id="mainContainer"></section>
    </div>
  </panZoom>

  <q-menu :touch-position="true" :target="isDisplayMenu" v-model="isOpen" :anchor="menuAnchor" :self="menuSelf">
    <q-list dense style="min-width: 100px">
      <!-- Type Question -->
      <q-item clickable>
        <q-item-section>{{ $t('menu.q_a') }}</q-item-section>
        <q-item-section side>
          <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <ScenarioConditionMenu
          :selected="selectedEditItem"
          :menu-anchor="selectedMenuAnchor"
          :menu-self="selectedMenuSelf"
          @update:onSelect="onAddNextClicked"
          cardType="question"
        />
      </q-item>

      <!-- Type Message -->
      <q-item clickable>
        <q-item-section>{{ $t('menu.message') }}</q-item-section>
        <q-item-section side>
          <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <ScenarioConditionMenu
          :selected="selectedEditItem"
          :menu-anchor="selectedMenuAnchor"
          :menu-self="selectedMenuSelf"
          @update:onSelect="onAddNextClicked"
          cardType="message"
        />
      </q-item>

      <!-- Type Goal -->
      <q-item clickable>
        <q-item-section>{{ $t('menu.goal') }}</q-item-section>
        <q-item-section side>
          <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <ScenarioConditionMenu
          :selected="selectedEditItem"
          :menu-anchor="selectedMenuAnchor"
          :menu-self="selectedMenuSelf"
          @update:onSelect="onAddNextClicked"
          cardType="goal"
        />
      </q-item>
      <!-- Type Form -->
      <q-item clickable>
        <q-item-section>{{ $t('menu.form') }}</q-item-section>
        <q-item-section side>
          <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <ScenarioConditionMenu
          :selected="selectedEditItem"
          :menu-anchor="selectedMenuAnchor"
          :menu-self="selectedMenuSelf"
          @update:onSelect="onAddNextClicked"
          cardType="form"
        />
      </q-item>
    </q-list>
  </q-menu>
  <div class="hidden">
    <q-checkbox size="xs" v-model="defaultAnswer" val="defaultAnswer" id="chkAnswer" label="Size 'xs'" />
    <q-btn
      size="xs"
      round
      color="white"
      text-color="primary"
      class="solid-border"
      label="Next"
      id="btnNext"
      style="border-color: #1976d2"
    />
    <q-btn size="xs" round color="white" text-color="black" class="solid-border" icon="edit" id="btnEdit" />
    <q-btn
      size="xs"
      round
      color="white"
      text-color="primary"
      class="solid-border"
      icon="add"
      id="btnAdd"
      style="border-color: #1976d2"
    />
    <q-btn
      size="xs"
      round
      color="white"
      text-color="red"
      class="solid-border"
      icon="delete"
      id="btnDelete"
      style="border-color: red"
    />
    <q-icon name="flag" color="white" size="sm" id="iconStart" />
    <q-icon name="message" color="white" size="sm" id="iconMessage" />
    <q-icon name="help_outline" color="white" size="sm" id="iconQuestion" />
    <q-icon name="verified" color="white" size="sm" id="iconGoal" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { Watch } from 'vue-property-decorator'
import { ACTION_FORM, ACTION_GOAL, ACTION_MESSAGES, ACTION_QUESTION, USER_ANSWER } from '@/store/actions'
import {
  EScenarioResourceType,
  IAnswer,
  IDateRangePicker,
  IForm,
  IGoal,
  IImageAnswer,
  IMessage,
  IQuestion,
  ISelectedItemCondition,
  ITextAnswer,
  IUserAnswer,
  IVersionHistory,
} from '@/utils/types'
import { randomString } from '@/utils/helpers'
import { constant, SCENARIO_MAKER } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import { ChatBotFlowsMaker } from 'bot-flow-maker/src/index'
import { ICard, ICardAnswer, ICardType } from 'bot-flow-maker/src/types'
import { debounce } from 'quasar'
import { DatetimeUtils } from '@/utils/datetime'
import CLoading from '../common/ui/CLoading.vue'
import ScenarioHeaderSelector from './selectors/ScenarioHeaderSelector.vue'
import ScenarioConditionMenu from './ui/ScenarioConditionMenu.vue'

@Options({
  components: {
    CLoading,
    ScenarioHeaderSelector,
    ScenarioConditionMenu,
  },
  directives: { maska },
})
export default class ScenariosMakerFrame extends Vue {
  selectedEditItem!: ICard
  value: ICard[] = []
  localValue: ICard[] = []
  chatbotFlow!: ChatBotFlowsMaker
  defaultAnswer = false
  currentZoom = 100
  isDisplayMenu = false
  isOpen = false
  dateRange: IDateRangePicker = DatetimeUtils.getDateRange('last30Days')

  titleLastHistory = ''
  loadingIFrame = true
  loadingResource = true
  directionType = '1'

  cardTypes: ICardType[] = [
    {
      name: EScenarioResourceType.question,
      displayText: 'Q/A',
    },
    {
      name: EScenarioResourceType.message,
      displayText: 'Message',
    },
    {
      name: EScenarioResourceType.goal,
      displayText: 'Goal',
    },
    {
      name: EScenarioResourceType.form,
      displayText: 'Form',
    },
  ]

  menuAnchor = 'bottom right'
  menuSelf = 'top start'
  selectedMenuAnchor = 'top right'
  selectedMenuSelf = 'top start'
  totalUsersReport = []
  errorCardId = ''
  usersAnswers: IUserAnswer[] = []

  get loading() {
    return this.loadingIFrame && this.loadingResource
  }

  get appId() {
    return this.$route?.params?.appId
  }

  get isReport() {
    return this.$route?.params?.isReport === 'true'
  }

  get isReadOnly() {
    return this.$route?.params?.isReadOnly === 'true'
  }

  get campaignId() {
    return this.$route?.params?.campaignId
  }

  get isTestMode() {
    return this.$route?.params?.isTestMode === 'true'
  }

  get messages() {
    return this.$store.getters.messages
  }

  get questions() {
    return this.$store.getters.questions
  }

  get goals() {
    return this.$store.getters.goals
  }

  get forms() {
    return this.$store.getters.forms
  }

  get typeMapping() {
    return {
      [EScenarioResourceType.question]: this.$t('menu.q_a'),
      [EScenarioResourceType.message]: this.$t('menu.message'),
      [EScenarioResourceType.goal]: this.$t('menu.goal'),
      [EScenarioResourceType.form]: this.$t('menu.form'),
      [EScenarioResourceType.start]: this.$t('label.start'),
    }
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }
    this.loadingResource = true
    await this.loadResources()
    this.loadingResource = false
    parent.postMessage({ action: SCENARIO_MAKER.FROM_IFRAME.READY }, '*')
  }

  async loadResources() {
    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_GOAL.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_FORM.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_MESSAGES.LOAD_ITEMS, {
      app_id: this.appId,
    })

    this.usersAnswers = await this.$store.dispatch(USER_ANSWER.GET_USER_ANSWER_BY_CAMPAIGN, {
      app_id: this.appId,
      campaign_id: this.campaignId,
      is_test_mode: this.isTestMode,
    })

    if (this.isReport) {
      await this.dateRangeChanged()
    }
  }

  getAnswers(data: IAnswer) {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    let result: any[] = []
    if (data.type === constant.ANSWER_TYPE.TEXT) {
      result = data.text_answers ?? []
    } else if (data.type === constant.ANSWER_TYPE.IMAGE) {
      result = data.image_answers ?? []
    } else if (data.type === constant.ANSWER_TYPE.BUTTON) {
      if (data.button_answer?.is_carousel) {
        const carousel_images = data.button_answer?.carousel_images ?? []
        result = []
        for (let index = 0; index < carousel_images.length; index++) {
          const element = carousel_images[index]
          if (element.actions) {
            const ac = element.actions.filter((p) => p.content !== undefined && p.content !== '')
            result = result.concat(ac)
          }
        }
      } else {
        const actions = data.button_answer?.actions ?? []
        result = []
        if (actions) {
          result = actions.filter((p) => p.content !== undefined && p.content !== '')
        }
      }
    } else if (data.type === constant.ANSWER_TYPE.IMAGEMAP) {
      result = data.imagemap_answer?.tappable_area ?? []
    } else {
      result = []
    }
    return result
  }

  getResourceByCard(card) {
    let resource

    if (card.cardType === EScenarioResourceType.question) {
      resource = this.questions.find((question) => question._id === card.id)
    } else if (card.cardType === EScenarioResourceType.message) {
      resource = this.messages.find((message) => message._id === card.id)
    } else if (card.cardType === EScenarioResourceType.goal) {
      resource = this.goals.find((goal) => goal._id === card.id)
    } else if (card.cardType === EScenarioResourceType.form) {
      resource = this.forms.find((form) => form._id === card.id)
    }
    return resource
  }

  addCardToLocalValue(resource, cardType: string, selectedItemCondition: ISelectedItemCondition) {
    const nextUniqueId = randomString()
    const answersData: ICardAnswer[] = [
      {
        id: 'any',
        title: this.$t('label.any'),
        nextCards: [],
        totalUsers: 0,
      },
    ]
    if (cardType !== EScenarioResourceType.message) {
      if (resource && resource.answers) {
        const answers: ITextAnswer[] | IImageAnswer[] | undefined = this.getAnswers(resource.answers)
        answers.forEach((element) => {
          const answer: ICardAnswer = {
            id: element._id ?? '',
            title: element?.label || element?.title || this.$t('label.any'),
            nextCards: [],
            totalUsers: 0,
          }

          answersData.push(answer)
        })
      }
    }

    const card: ICard = {
      id: resource._id ?? '',
      displayId: '',
      uniqueId: nextUniqueId,
      cardType: cardType,
      title: resource.title ?? '',
      titleBadge: this.typeMapping[cardType],
      ...this.getMainContainerMiddlePos(),
      answers: answersData,
      totalUsers: 0,
      labelTotalUsers: this.$t('label.user'),
    }

    this.chatbotFlow.addCard(card)
    this.localValue.push(card)
    this.updateNextCards(nextUniqueId, selectedItemCondition)
    this.chatbotFlow.render()
  }

  updateNextCards(toUniqueId, selectedItemCondition: ISelectedItemCondition) {
    const card = this.localValue.find((item) => item.uniqueId === selectedItemCondition.uniqueId)
    if (card) {
      const answers = card.answers
      let selectedAnswerIds = selectedItemCondition.selectedAnswerIds
      if (selectedAnswerIds) {
        if (selectedAnswerIds.includes('any')) {
          answers.forEach((item) => {
            item.nextCards = []
          })
          selectedAnswerIds = ['any']
        } else {
          const anyAnswer = answers.find((item) => item.id === 'any')
          if (anyAnswer) {
            anyAnswer.nextCards = []
          }
        }
        selectedAnswerIds.forEach((id) => {
          const answer = answers.find((item) => item.id === id)
          if (answer) {
            let nodeIndex = 1
            if (this.directionType === '0') {
              nodeIndex = 7
            }
            answer.nextCards = []
            answer.nextCards.push({
              nodeIndex: nodeIndex,
              uniqueId: toUniqueId,
            })
          }
        })
      }
    }

    this.chatbotFlow.updateCard(selectedItemCondition.uniqueId ?? '', card)
  }

  @Watch('localValue', { deep: true })
  localValueChanged() {
    const localValue = cloneDeep(this.localValue)
    this.value = localValue
    debounce(() => {
      this.sendDataToParent()
      this.setParentEditingMode(0)
    }, 100).call(this)
  }

  getMainContainer() {
    return document.getElementsByTagName('BODY')[0] as HTMLElement
  }

  getMainContainerMiddlePos() {
    const mainContainer = this.getMainContainer()
    const bounding = mainContainer.getBoundingClientRect()
    const top = mainContainer.scrollTop | Math.abs(bounding.top)
    const left = mainContainer.scrollLeft | Math.abs(bounding.left)
    return {
      left: left + 200,
      top: top + 200,
    }
  }

  onChangeCards(newValue) {
    this.localValue = newValue
  }

  onCardEdit(uniqueId) {
    parent.postMessage(
      {
        action: SCENARIO_MAKER.FROM_IFRAME.ON_CARD_EDIT,
        data: uniqueId,
      },
      '*'
    )
  }

  onOpenPreview() {
    parent.postMessage(
      {
        action: SCENARIO_MAKER.FROM_IFRAME.ON_OPEN_PREVIEW,
        data: '',
      },
      '*'
    )
  }

  openSelectNextQuestion(data) {
    parent.postMessage(
      {
        action: SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_QUESTION,
        data: data,
      },
      '*'
    )
  }

  openSelectNextMessage(data) {
    parent.postMessage(
      {
        action: SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_MESSAGE,
        data: data,
      },
      '*'
    )
  }

  openSelectNextGoal(data) {
    parent.postMessage(
      {
        action: SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_GOAL,
        data: data,
      },
      '*'
    )
  }

  openSelectNextForm(data) {
    parent.postMessage(
      {
        action: SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_FORM,
        data: data,
      },
      '*'
    )
  }

  onAddCard(uniqueId) {
    this.isDisplayMenu = true
    const item = this.localValue.find((item) => item.uniqueId === uniqueId)
    if (item) {
      const html = document.documentElement
      const cardEl = document.getElementById(item.uniqueId) as HTMLElement
      const btnAdd = cardEl.querySelector('#btnAdd') as HTMLElement
      const bounding = btnAdd.getBoundingClientRect()
      if (html.offsetWidth - bounding.left < 150) {
        this.menuAnchor = 'bottom left'
        this.menuSelf = 'top end'
      } else {
        this.menuAnchor = 'bottom right'
        this.menuSelf = 'top start'
      }
      if (html.offsetWidth - bounding.left < 300) {
        this.selectedMenuAnchor = 'top left'
        this.selectedMenuSelf = 'top end'
      } else {
        this.selectedMenuAnchor = 'top right'
        this.selectedMenuSelf = 'top start'
      }
      this.selectedEditItem = item
    }
  }

  async filterUserAnaswersByDateRange() {
    this.totalUsersReport = await this.$store.dispatch(USER_ANSWER.TOTAL_USERS_REPORT_SCENARIO, {
      app_id: this.appId,
      campaign_id: this.campaignId,
      is_test_mode: this.isTestMode,
      date_from: DatetimeUtils.formatDate(this.dateRange.startDate),
      date_to: DatetimeUtils.formatDate(this.dateRange.endDate),
    })
  }

  countUsersByUniqueId(prefix: string) {
    let total = 0
    for (const item of this.totalUsersReport) {
      // eslint-disable-next-line
      const key = item['unique_id'] + '_' + item['answer_id']
      if (key.startsWith(prefix)) {
        // eslint-disable-next-line
        total = total + item['total']
      }
    }

    return total
  }

  /**
   * localValue contains itemData, we do not store this field
   */
  makeLocalValue() {
    this.chatbotFlow.removeAllCards()
    const cards: ICard[] = cloneDeep(this.value) || []

    for (const card of cards) {
      if (this.isReport) {
        const a = this.usersAnswers.filter((p) => p.next_unique_id === card.uniqueId)
        const uniqueUserIds = new Set(a.map((p) => p.user_id))
        card.totalUsers = uniqueUserIds.size
      }

      if (card.uniqueId === EScenarioResourceType.start) {
        card.titleBadge = ''
        card.title = this.$t('label.start')
        const answers = card.answers.find((item) => item.id === 'any')
        const answersData: ICardAnswer[] = []
        const anyCardAnswer: ICardAnswer = {
          id: 'any',
          title: this.$t('label.any'),
          nextCards: answers?.nextCards || [],
          totalUsers: 0,
        }
        answersData.push(anyCardAnswer)
        card.answers = answersData
      } else {
        const resource = this.getResourceByCard(card)
        if (resource) {
          const answers = card.answers.find((item) => item.id === 'any')
          const answersData: ICardAnswer[] = []
          const anyCardAnswer: ICardAnswer = {
            id: 'any',
            title: this.$t('label.any'),
            nextCards: answers?.nextCards || [],
            totalUsers: 0,
          }

          card.displayId = ''
          card.title = resource.title ?? ''
          card.titleBadge = this.typeMapping[card.cardType]

          if (this.isReport) {
            let totalDetailUsers = 0
            if (card.totalUsers !== 0 && answers?.nextCards && answers?.nextCards.length > 0) {
              const key = answers?.nextCards[0].uniqueId
              totalDetailUsers = this.countUsersByUniqueId(key)
            }
            anyCardAnswer.totalUsers = totalDetailUsers
          }

          answersData.push(anyCardAnswer)
          if (card.cardType !== EScenarioResourceType.message && card.cardType !== EScenarioResourceType.form) {
            const answers: ITextAnswer[] | IImageAnswer[] | undefined = this.getAnswers(resource.answers)
            answers.forEach((elm) => {
              const cardAnswer = card.answers.find((item) => item.id === elm._id)
              let totalDetailUsers = 0
              if (card.totalUsers !== 0 && this.isReport && cardAnswer?.nextCards && cardAnswer?.nextCards.length > 0) {
                const key = cardAnswer?.nextCards[0].uniqueId + '_' + elm._id
                totalDetailUsers = this.countUsersByUniqueId(key)
              }

              answersData.push({
                id: elm._id ?? '',
                title: elm?.label || elm?.title || this.$t('label.any'),
                nextCards: cardAnswer?.nextCards || [],
                totalUsers: totalDetailUsers,
              })
            })
          }
          card.answers = answersData
        }

        card.labelTotalUsers = this.$t('label.user')
      }

      this.chatbotFlow.addCard(card)
    }

    this.localValue = cards
    this.chatbotFlow.render()
  }

  onSelectNextDone(
    data: IQuestion | IMessage | IGoal | IForm,
    type: EScenarioResourceType,
    selectedItemCondition: ISelectedItemCondition
  ) {
    this.addCardToLocalValue(data, type, selectedItemCondition)
  }

  onReUndoCards(data) {
    this.value = data
    this.makeLocalValue()
  }

  initScenarios(data) {
    this.value = data
    this.initChatBotFlowsMaker()
    this.makeLocalValue()
  }

  onReceiveValueFromParent(data: ICard[]) {
    this.value = data
    this.makeLocalValue()
    this.setScrollLeftToCenter()
  }

  setScrollLeftToCenter() {
    const html = document.documentElement
    let left = (html.scrollWidth - html.clientWidth) / 2
    if (this.value.length > 0) {
      for (const card of this.value) {
        if (left === 0) {
          left = card.left
        } else {
          if (card.left < left) {
            left = card.left
          }
        }
      }
    }
    html.scrollLeft = left
  }

  sendDataToParent() {
    if (this.isReport) {
      return
    }

    parent.postMessage(
      {
        action: SCENARIO_MAKER.FROM_IFRAME.IFRAME_NEW_DATA,
        data: JSON.stringify(this.value),
      },
      '*'
    )
  }

  /**
   * @param value: 0: not editing |1: editing (has modal opening)
   */
  setParentEditingMode(value: number) {
    if (this.isReport) {
      return
    }
    parent.postMessage(
      {
        action: SCENARIO_MAKER.FROM_IFRAME.IS_EDITING,
        data: value,
      },
      '*'
    )
  }

  @Watch('dateRange')
  async dateRangeChanged() {
    await this.filterUserAnaswersByDateRange()
    this.makeLocalValue()
  }

  postRangeCondtion() {
    parent.postMessage(
      {
        action: SCENARIO_MAKER.FROM_IFRAME.DATE_RANGE,
        data: cloneDeep(this.dateRange),
      },
      '*'
    )
  }

  initChatBotFlowsMaker() {
    const container = document.getElementById('mainContainer')
    const btnNext = document.getElementById('btnNext') as HTMLButtonElement
    const btnAdd = document.getElementById('btnAdd') as HTMLButtonElement
    const btnEdit = document.getElementById('btnEdit') as HTMLButtonElement
    const btnDelete = document.getElementById('btnDelete') as HTMLButtonElement
    const iconStart = document.getElementById('iconStart') as HTMLElement
    const iconMessage = document.getElementById('iconMessage') as HTMLElement
    const iconQuestion = document.getElementById('iconQuestion') as HTMLElement
    const iconGoal = document.getElementById('iconGoal') as HTMLElement

    if (btnNext) {
      this.chatbotFlow = new ChatBotFlowsMaker(
        container,
        btnNext,
        btnAdd,
        btnEdit,
        btnDelete,
        iconStart,
        iconMessage,
        iconQuestion,
        iconGoal,
        this.isReport,
        false
      )
      this.chatbotFlow.onChange(this.onChangeCards)
      this.chatbotFlow.onCardEdit(this.onCardEdit)
      this.chatbotFlow.setCardTypes(this.cardTypes)
      this.chatbotFlow.onAddCard(this.onAddCard)
      if (container) {
        const rect = container.getBoundingClientRect()
        this.chatbotFlow.setBoundingClientRectBeforeZoom(rect)
      }
    }
  }

  onAddNextClicked(data) {
    this.isOpen = false
    if (data.cardType === EScenarioResourceType.question) {
      this.openSelectNextQuestion(data)
    } else if (data.cardType === EScenarioResourceType.goal) {
      this.openSelectNextGoal(data)
    } else if (data.cardType === EScenarioResourceType.form) {
      this.openSelectNextForm(data)
    } else {
      this.openSelectNextMessage(data)
    }
  }

  onSelectHistoryDone(item: IVersionHistory) {
    if (item.campaign) {
      let scenario
      if (this.isTestMode) {
        scenario = item.campaign.test_detail?.scenarios
      } else {
        scenario = item.campaign.prod_detail?.scenarios
      }
      this.value = scenario
      this.makeLocalValue()
    }
  }

  onHandleErrorCard(uniqueId) {
    for (const item of this.localValue) {
      const cardEl = document.getElementById(item.uniqueId) as HTMLElement
      cardEl.classList.remove('error-card')
    }

    const card = this.updateErrorCard(uniqueId)
    if (card) {
      const html = document.documentElement
      html.scrollLeft = card.left
      html.scrollTop = card.top - 200
      this.setNextTargetErrorCards(card)
      this.setPrevTargetErrorCards(card)
    }
  }

  updateErrorCard(uniqueId) {
    const card = this.localValue.find((item) => item.uniqueId === uniqueId)
    if (card) {
      const cardEl = document.getElementById(card.uniqueId) as HTMLElement
      cardEl.classList.add('error-card')
    }
    return card
  }

  setNextTargetErrorCards(card: ICard) {
    for (const answer of card.answers) {
      for (const next of answer.nextCards) {
        const child = this.localValue.find((item) => item.uniqueId === next.uniqueId)
        if (child) {
          if (child.cardType !== EScenarioResourceType.message) {
            return
          }

          this.updateErrorCard(child.uniqueId)
          this.setNextTargetErrorCards(child)
        }
      }
    }
  }

  setPrevTargetErrorCards(card: ICard) {
    for (const item of this.localValue) {
      for (const answer of item.answers) {
        for (const next of answer.nextCards) {
          if (next.uniqueId === card.uniqueId && item.cardType === EScenarioResourceType.message) {
            this.updateErrorCard(item.uniqueId)
            this.setPrevTargetErrorCards(item)
          }
        }
      }
    }
  }

  handleZoomInOut(zoomLevel) {
    const transform = this.$refs.panZoom.$panZoomInstance.getTransform()
    const deltaX = transform.x
    const deltaY = transform.y
    const scale = zoomLevel / 100
    // const scale = zoomLevel / 100
    const offsetX = scale + deltaX
    const offsetY = scale + deltaY
    this.$refs.panZoom.$panZoomInstance.zoomAbs(offsetX, offsetY, scale)
    this.chatbotFlow.setZoomLevel(zoomLevel)
  }

  onTransform() {
    if (this.chatbotFlow.getZoomLevel() !== this.currentZoom) {
      this.chatbotFlow.render()
      this.currentZoom = this.chatbotFlow.getZoomLevel()
    }
  }

  onPauseZoom() {
    this.$refs.panZoom.$panZoomInstance.pause()
  }

  onChangeDirection(value) {
    parent.postMessage(
      {
        action: SCENARIO_MAKER.FROM_IFRAME.ON_CHANGE_DIRECTION,
        data: value,
      },
      '*'
    )

    this.chatbotFlow.setDirectionType(value)
    this.chatbotFlow.render()
  }

  onOpenHistory() {
    parent.postMessage(
      {
        action: SCENARIO_MAKER.FROM_IFRAME.ON_OPEN_HISTORY,
        data: '',
      },
      '*'
    )
  }

  setLoading(value) {
    this.loadingIFrame = value
  }

  setDirectionType(value) {
    this.directionType = value
    this.$refs.scenarioHeader.setDirectionType(value)
  }

  setTitleHistory(value) {
    this.titleLastHistory = value
  }

  handleKeyPress(event) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      this.$refs.scenarioHeader.handleUndo()
    }
  }

  handleMessage(event) {
    if (!event.data.action) {
      return
    }

    const action = event.data.action
    const data = JSON.parse(event.data.data.data)
    const isTestMode = event.data.data.isTestMode
    if (isTestMode !== this.isTestMode) {
      // console.log('isTestMode 1:', isTestMode)
      return
    }

    if (action === SCENARIO_MAKER.FROM_PARENT.CURRENT_VALUE) {
      this.setLoading(true)
      this.onReceiveValueFromParent(data)
      this.setLoading(false)
    } else if (action === SCENARIO_MAKER.FROM_PARENT.ADD_CARD) {
      this.onSelectNextDone(data.resource, data.cardType, data.selectedItemCondition)
    } else if (action === SCENARIO_MAKER.FROM_PARENT.DIRECTION) {
      this.setDirectionType(data)
    } else if (action === SCENARIO_MAKER.FROM_PARENT.MSG_LAST_HISTORY) {
      this.setTitleHistory(data)
    } else if (action === SCENARIO_MAKER.FROM_PARENT.ITEM_HISTORY) {
      this.onSelectHistoryDone(data)
    } else if (action === SCENARIO_MAKER.FROM_PARENT.ERROR_CARD) {
      this.onHandleErrorCard(data)
    } else if (action === SCENARIO_MAKER.FROM_PARENT.CTRL_Z) {
      this.$refs.scenarioHeader.handleUndo()
    }
  }

  async mounted() {
    window.addEventListener('click', () => {
      this.isDisplayMenu = false
    })
    window.addEventListener('message', async (event) => this.handleMessage(event))
    window.addEventListener('keydown', this.handleKeyPress)

    this.initChatBotFlowsMaker()
  }

  async beforeUnmount() {
    window.removeEventListener('click', () => {
      this.isDisplayMenu = false
    })
    window.removeEventListener('message', async (event) => this.handleMessage(event))
    window.removeEventListener('keydown', this.handleKeyPress)
  }
}
</script>
<style lang="scss">
body {
  height: 3000px;
  width: 3000px;
  top: 0px !important;
  left: 0px !important;
  position: absolute !important;
  background-color: #fff;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      rgba(0, 0, 0, 0.05) 25%,
      rgba(0, 0, 0, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(0, 0, 0, 0.05) 75%,
      rgba(0, 0, 0, 0.05) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      rgba(0, 0, 0, 0.05) 25%,
      rgba(0, 0, 0, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(0, 0, 0, 0.05) 75%,
      rgba(0, 0, 0, 0.05) 76%,
      transparent 77%,
      transparent
    );
  background-size: 50px 50px;
}
.scenario {
  &__item {
    width: 240px;
  }
  &__container {
    height: 3000px;
    width: 3000px;
  }
}
.scenario-header {
  background-color: #d8d8d8;
}

.error-card {
  border: 2px solid red;
}

html::-webkit-scrollbar {
  width: 1em;
}

html::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

html::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

#card-delete-btn-start {
  display: none;
}

#movearea-start {
  padding-top: 8px;
}
</style>

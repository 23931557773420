import moment from 'moment-timezone'
import { constant } from './constants'
import { localStore } from './localstore'
import { IDateRangePicker } from '@/utils/types'
import dayjs from 'dayjs'

const formatDatetime = 'yyyy-mm-dd'
const lang = localStore.getItem('lang') || 'jp'

const epochs = [
  [lang === 'jp' ? '年間' : ' year', 31536000],
  [lang === 'jp' ? 'ヶ月' : ' month', 2592000],
  [lang === 'jp' ? '日間' : ' day', 86400],
  [lang === 'jp' ? '時間' : ' hour', 3600],
  [lang === 'jp' ? '分' : ' minute', 60],
  [lang === 'jp' ? '秒' : ' second', 1],
]

// Get duration
const getDuration = (timeAgoInSeconds) => {
  for (const epoch of epochs) {
    const name = epoch[0]
    const seconds = epoch[1] as number

    const interval = Math.floor(timeAgoInSeconds / seconds)

    if (interval >= 1) {
      return {
        interval: interval,
        epoch: name,
      }
    }
  }
}

export const localTime = (dateTime, format = constant.DATETIME_FORMAT) => {
  const result = moment(moment.utc(dateTime)).local()
  if (result.isValid()) {
    return result.format(format)
  }

  return '-'
}

export const convertStrToDate = (dateTime, format = constant.DATETIME_FORMAT) => {
  return moment(dateTime, format)
}

export const last7Days = (format = constant.DATE_FORMAT) => {
  const last7days = moment()
    .subtract(1, 'weeks')
    .toDate()
  last7days.setHours(0)
  last7days.setMinutes(0)
  last7days.setSeconds(0)

  return moment(last7days).format(format)
}

export const lastNMonth = (n: number, format = constant.DATE_FORMAT) => {
  const last = moment()
    .subtract(n, 'months')
    .toDate()
  last.setHours(0)
  last.setMinutes(0)
  last.setSeconds(0)

  return moment(last).format(format)
}

export const now = (format = constant.DATE_FORMAT) => {
  return moment().format(format)
}

export const lastNDays = (date: string, n: number, format = constant.DATE_FORMAT) => {
  const last = moment(date, format)
    .subtract(n, 'days')
    .toDate()
  last.setHours(0)
  last.setMinutes(0)
  last.setSeconds(0)

  return moment(last).format(format)
}

export const nextNDays = (date: string, n: number, format = constant.DATE_FORMAT) => {
  const next = moment(date, format)
    .add(n, 'days')
    .toDate()
  next.setHours(0)
  next.setMinutes(0)
  next.setSeconds(0)

  return moment(next).format(format)
}

export const localeData = () => {
  console.log(lang)
  if (lang === 'en') {
    return {
      direction: 'ltr',
      format: formatDatetime,
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      firstDay: 0,
    }
  }

  return {
    direction: 'ltr',
    format: formatDatetime,
    separator: ' - ',
    applyLabel: '適用',
    cancelLabel: 'キャンセル',
    weekLabel: 'W',
    customRangeLabel: 'カスタム範囲',
    daysOfWeek: ['日', '月', '火', '水', '木', '金', '土'],
    monthNames: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
    firstDay: 0,
    localeRanges: {
      today: '今日',
      yesterday: '昨日',
      last1Week: '1週間前',
      thisMonth: '今月',
      lastMonth: '先月',
      thisYear: '今年',
    },
  }
}

export const localeRanges = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const todayEnd = new Date()
  todayEnd.setHours(11, 59, 59, 999)

  const yesterdayStart = new Date()
  yesterdayStart.setDate(today.getDate() - 1)
  yesterdayStart.setHours(0, 0, 0, 0)

  const yesterdayEnd = new Date()
  yesterdayEnd.setDate(today.getDate() - 1)
  yesterdayEnd.setHours(11, 59, 59, 999)

  const thisWeekStart = new Date()
  thisWeekStart.setDate(today.getDate() - today.getDay()) // Start of this week (Sunday)
  thisWeekStart.setHours(0, 0, 0, 0)

  const thisWeekEnd = new Date()
  thisWeekEnd.setDate(thisWeekStart.getDate() + 6) // End of this week (Saturday)
  thisWeekEnd.setHours(23, 59, 59, 999)

  const lastWeekStart = new Date()
  lastWeekStart.setDate(today.getDate() - 7)
  lastWeekStart.setHours(0, 0, 0, 0)

  const lastWeekEnd = new Date()
  lastWeekEnd.setDate(today.getDate() - 1)
  lastWeekEnd.setHours(23, 59, 59, 999)

  const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
  const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999)

  if (lang === 'en') {
    return {
      Today: [today, todayEnd],
      Yesterday: [yesterdayStart, yesterdayEnd],
      'This week': [thisWeekStart, thisWeekEnd],
      'Last Week': [lastWeekStart, lastWeekEnd],
      'This month': [thisMonthStart, thisMonthEnd],
      'Last month': [
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999),
      ],
      'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)],
    }
  }

  return {
    今日: [today, todayEnd],
    昨日: [yesterdayStart, yesterdayEnd],
    今週: [thisWeekStart, thisWeekEnd],
    先週: [lastWeekStart, lastWeekEnd],
    今月: [thisMonthStart, thisMonthEnd],
    先月: [
      new Date(today.getFullYear(), today.getMonth() - 1, 1),
      new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999),
    ],
    今年: [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)],
  }
}

export const DatetimeUtils = {
  timeAgo: (datetime) => {
    const datetimeUtc = moment.utc(datetime, formatDatetime)
    const timeAgoInSeconds = moment.utc().diff(datetimeUtc, 'seconds')

    const du = getDuration(timeAgoInSeconds)
    if (du) {
      const interval = du.interval
      const epoch = du.epoch
      let suffix
      if (lang === 'jp') {
        suffix = ''
      } else {
        suffix = interval === 1 ? '' : 's'
      }

      return `${interval}${epoch}${suffix}`
    }
  },

  now: () => {
    return moment().format()
  },

  dateStrToFormat: (dateStr: string) => {
    return moment(dateStr).format('YYYY/MM/DD HH:mm:ss')
  },

  nowEnd: () => {
    const now = moment().toDate()
    now.setHours(23)
    now.setMinutes(59)
    now.setSeconds(59)

    return moment(now).format()
  },

  last7Days: () => {
    const last7days = moment()
      .subtract(1, 'weeks')
      .toDate()
    last7days.setHours(0)
    last7days.setMinutes(0)
    last7days.setSeconds(0)

    return moment(last7days).format()
  },

  lastNMonth: (n: number) => {
    const last = moment()
      .subtract(n, 'months')
      .toDate()
    last.setHours(0)
    last.setMinutes(0)
    last.setSeconds(0)

    return moment(last).format()
  },

   /* eslint-disable  @typescript-eslint/no-explicit-any */
  getTime: (dateOrTime) => {
    return new Date(dateOrTime).getTime()
  },

  getDatetimeFormat: () => {
    return formatDatetime
  },

  formatDate: (date: string) => {
    if (date) {
      return dayjs(date).format('YYYY-MM-DD')
    }

    return ''
  },

  getDateRange: (currentDateType) => {
    const today = new Date()
    const formattedToday = today.toLocaleDateString('en-ZA')
    const dateRange: IDateRangePicker = {
      startDate: '',
      endDate: '',
    }
    switch (currentDateType) {
      case 'today': {
        dateRange.startDate = formattedToday
        dateRange.endDate = formattedToday
        break
      }
      case 'yesterday': {
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        dateRange.startDate = yesterday.toLocaleDateString('en-ZA')
        dateRange.endDate = yesterday.toLocaleDateString('en-ZA')
        break
      }
      case 'thisWeek': {
        const currentDayOfWeek = today.getDay()
        const adjustedDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek
        const startOfWeek = new Date(today)
        startOfWeek.setDate(today.getDate() - adjustedDayOfWeek + 1)
        const endOfWeek = new Date(today)
        endOfWeek.setDate(today.getDate() + (7 - adjustedDayOfWeek))
        dateRange.startDate = startOfWeek.toLocaleDateString('en-ZA')
        dateRange.endDate = endOfWeek.toLocaleDateString('en-ZA')
        break
      }
      case 'lastWeek': {
        const currentDayOfWeek = today.getDay()
        const adjustedDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek
        const startOfPreviousWeek = new Date(today)
        startOfPreviousWeek.setDate(today.getDate() - adjustedDayOfWeek - 6)
        const endOfPreviousWeek = new Date(today)
        endOfPreviousWeek.setDate(today.getDate() - adjustedDayOfWeek)
        dateRange.startDate = startOfPreviousWeek.toLocaleDateString('en-ZA')
        dateRange.endDate = endOfPreviousWeek.toLocaleDateString('en-ZA')
        break
      }
      case 'last7Days': {
        const startOfPreviousWeek = new Date(today)
        startOfPreviousWeek.setDate(today.getDate() - 7)
        dateRange.startDate = startOfPreviousWeek.toLocaleDateString('en-ZA')
        dateRange.endDate = formattedToday
        break
      }
      case 'last30Days': {
        const startOfPreviousWeek = new Date(today)
        startOfPreviousWeek.setMonth(startOfPreviousWeek.getMonth() - 1)
        dateRange.startDate = startOfPreviousWeek.toLocaleDateString('en-ZA')
        dateRange.endDate = formattedToday
        break
      }
      case 'thisMonth': {
        const year = today.getFullYear()
        const month = today.getMonth()
        const startOfMonth = new Date(year, month, 1)
        const endOfMonth = new Date(year, month + 1, 0)
        dateRange.startDate = startOfMonth.toLocaleDateString('en-ZA')
        dateRange.endDate = endOfMonth.toLocaleDateString('en-ZA')
        break
      }
      case 'lastMonth': {
        const year = today.getFullYear()
        const month = today.getMonth()
        const startOfPreviousMonth = new Date(year, month - 1, 1)
        const endOfPreviousMonth = new Date(year, month, 0)
        dateRange.startDate = startOfPreviousMonth.toLocaleDateString('en-ZA')
        dateRange.endDate = endOfPreviousMonth.toLocaleDateString('en-ZA')
        break
      }
      default: {
        dateRange.startDate = ''
        dateRange.endDate = ''
        break
      }
    }
    return dateRange
  },
}
